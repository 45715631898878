import workerMap from './workerMap.js'

(() => {
  const WORKER_PATH = 'swTemplate.js';

  if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
      workerMap.serviceWorkers.forEach( async ({ scope, appName }) => {
        const registration = await navigator.serviceWorker.getRegistration(scope);
        
        if(registration) {
          console.log(`Service Worker for '${appName}' already registered in '${registration.scope}' scope.`);
        } else {
          try {
            const success = await navigator.serviceWorker.register(WORKER_PATH, { scope });
            const baseURL = System.resolve(appName);

            success.installing.postMessage({ baseURL });

            console.log(`Service Worker for '${appName}' registered in '${success.scope}' scope.`);
          } catch(error) {
            console.error(`Service Worker for '${appName}' registration failed.`, error);
          }
        }
      })
    });
  }
})();