export const importChunkedApp = async (entryPath) => {
  try {
    const response = await fetch(entryPath);
    const { scripts, webmanifest } = await response.json();
    const { props: { src: appEntrypoint } } = scripts.pop(); // main chunk is always last (@loadable/webpack-plugin)
    const chunkPromises = [];

    // insert webmanifest first as some chunks depend on it
    document.head.insertAdjacentHTML('beforeend', `<link rel="manifest" href=${webmanifest} type="application/manifest+json" />`);

    // import chunks
    for (const url of scripts) {
      url.props.src && chunkPromises.push(System.import(url.props.src));
    }

    await Promise.all(chunkPromises);

    // import entrypoint once chunks are resolved
    return System.import(appEntrypoint);
  }
  catch (error) {
    console.log(error);
  }
};